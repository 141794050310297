@import url("./fonts/satoshi.css");

.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.profilePic {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-right: 20px;
}

.socialLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-right: 20px;
}

.name {
    font-size: 1.7em;
    margin-right: 15px;
    justify-content: center;
    font-family: 'Satoshi';
}

.ens {
    font-size: 1.2em;
    font-family: 'Satoshi-MediumItalic';
    justify-content: center;
    margin-top: -10px;
    color: lightskyblue;
    margin-right: 20px;
}

.xIcon {
    font-size: 4em;
    color: #000000;
    margin-bottom: 10px;
}

.githubIcon {
    font-size: 4em;
    color: #24292e;
    margin-bottom: 10px;
}

.discordIcon {
    font-size: 4em;
    color: #7289da;
    margin-bottom: 10px;
}
.telegramIcon {
    font-size: 4em;
    color: #229ED9;
    margin-bottom: 10px;
}
.linkedinIcon {
    font-size: 4em;
    color: #0a66c2;
    margin-bottom: 10px;
}