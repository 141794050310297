@import url("./fonts/satoshi.css");

.artContainer {
    text-align: center;
    margin: 20px;
}

.artImage {
    width: 100%;
    max-width: 700px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 1, 1, .1);
}
.artTitle {
    font-family: 'Satoshi';
    font-size: 2rem;
    justify-content: center;
}
.artDescription {
    margin-top: 10px;
    font-size: 1rem;
    font-family: 'Satoshi-Medium';
    color: #333;
}

.title {
    font-family: "Satoshi";
    font-size: 2em;
    text-align: left;
    color: #333;

}