@import url("./fonts/satoshi.css");

.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 25px;
    padding: 15px;
    justify-content: center; 
    width: fit-content; 
    margin: auto;
}
  
.token {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    max-width: 200px; 
}

.image {
    width: 100%;
    height: 200px;
    display: block;
    object-fit: cover;
}

.tokenTitle {
    font-family: 'Satoshi';
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
}

.title {
    font-family: "Satoshi";
    font-size: 2em;
    text-align: left;
    color: #333;

}

@media screen and (max-width: 768px) {
    .gallery {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
        gap: 10px; 
    }

    .token {
        max-width: 150px; /* Adjust max-width for smaller screens */
    }
}