@font-face {
    font-family: 'Satoshi';
    src: url('./Satoshi-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('./Satoshi-Medium.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./Satoshi-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('./Satoshi-MediumItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

