@import url("./fonts/satoshi.css");

.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center tabs horizontally */
  position: fixed;
  top: 0; 
  right: 0;
  left: 0;
  background-color: white;
  z-index: 2; /* Ensure it's above the profile container */
  width: 100%;
}


.appContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    align-items: center;    
  }
  justify-content: flex-start; /* Change justify-content to flex-start */
  min-height: 100vh;
  padding-top: 80px; /* Adjust to match the height of the fixed profile */
}


.tabsList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.tab {
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: left;
  font-family: 'Satoshi';
  font-size: 22px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab:hover {
  text-decoration: underline;
}

.activeTab {
  font-family: 'Satoshi';
  font-size: 22px;
  color: red;
}

.tabContent {
  flex-grow: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; 
} 

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  justify-content: center; 
  top: 0;
  left: 0; 
  position: fixed;
  margin-top: 60px;
}


@media (max-width: 768px) {
  .profileContainer {
    position: relative; /* Change to relative positioning */
    margin-top: 0; /* Remove top margin */
    width: 100%; /* Occupy the full width */
  }
}