@import url("./fonts/satoshi.css");

.entry {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px; /* Example: Set a maximum width */

    
  }
  
.title {
    margin: 0 0 10px 0;
    color: #333;
    font-family: "Satoshi-Bold";
}

.date {
    margin: 0 0 15px 0;
    color: #666;
    font-family: "Satoshi";
}

.description {
    margin: 0;
    color: #444;
    font-family: "Satoshi-Medium";
}

.fullContent {
    font-family: "Satoshi";
}

.readMore {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px; 
    color: #ffffff; 
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px; 
    border: none; 
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer; 
    font-family: "Satoshi-Bold";
}

.readMore:hover {
    background-color: #0056b3; 
    color: #e0e0e0;
}


.leftContent {
    flex: 1;
}

.rightContent {
    flex-shrink: 0; /* Prevent the right content from shrinking */
    margin-left: 20px; /* Add spacing between left and right content */
}


.imageStyle {
    max-width: 100%; /* Set the maximum width to 100% of the available space */
    height: auto; /* Maintain image proportions */
}

.responsiveImage {
    width: auto;
    height: auto;
    max-height: 100%; /* Limit the image height to 100% of its container */
  }
