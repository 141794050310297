@import url("./fonts/satoshi.css");

.aboutMeContainer {
    max-width: 900px;
    font-family: 'Satoshi';
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    margin-left: 25px;
    margin-top: 50px;

  }
  
  .aboutMeContainer h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-family: 'Satoshi';
  }
  
  .content {
    text-align: left;
  }

  

  

