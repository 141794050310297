@import url("./fonts/satoshi.css");


.container {
    display: flex;
    flex-direction: column;
    color: #333;
}
  
.title {
    font-family: "Satoshi";
    font-size: 2em;
    text-align: left;
}

